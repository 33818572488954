<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новый партнер"
        create
        url-update="/partner/update/:id"
        url-back="/partner"
    >
        <partner-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import PartnerForm from '@/components/forms/PartnerForm.vue'
export default {
    name: 'PartnerCreate',
    components: { FormView, PartnerForm }
}
</script>